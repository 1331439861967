const host = (window.location.host.includes('doancuong')
  || window.location.host.includes('local')) ? 'https://songlong.doancuong.com' : 'http://103.27.63.66'

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Song Long', // Will update name in navigation menu (Branding)
    website: 'https://muabannosonglong.com/',
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/icon-dola-songlong.png'), // Will update logo in navigation menu (Branding)
    // eslint-disable-next-line global-require
    loginBackground: require('@/assets/images/pages/login-v2.svg'),
    urlUpload: `${host}/api.upload`,
    host,
    // urlUpload: 'https://songlong.doancuong.com/api.upload',
    textUnauthorized: 'Phiên đăng nhập hết hạn, hệ thống sẽ đăng xuất!',
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
