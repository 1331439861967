export default [
  {
    path: '/plans/list',
    name: 'plans-list',
    component: () => import('@/views/plans/list/PlanList.vue'),
    meta: {
      action: 'read',
      resource: 'Plans',
      breadcrumb: [
        {
          text: 'Kế hoạch làm việc',
          active: true,
        },
      ],
    },
  },
]
