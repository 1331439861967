import { Ability, AbilityBuilder } from '@casl/ability'

const ability = newPermissions => {
  let jsonUserData = localStorage.getItem('userData') || '{}'
  jsonUserData = jsonUserData === 'undefined' ? '{}' : jsonUserData
  const userData = JSON.parse(jsonUserData) || {}
  const permissions = userData?.user_role?.permission || []
  const isAdmin = permissions.find(p => p.code === 'ADMINISTRATION')?.is_allow

  const {
    can,
    cannot,
    rules,
  } = new AbilityBuilder(Ability)

  const allowList = (newPermissions || permissions).filter(i => i.is_allow)
    .map(i => i.code) || []
  const uCan = []
  const uCant = []

  uCan.push(['read', 'Auth'])
  uCan.push(['read', 'ReportsTitle'])
  uCan.push(['read', 'ReportsUser'])

  // todo: để nhân viên nhập liệu, sau đó cần xóa đi
  uCan.push(['assigned_to_team', 'Contract', {
    status: { $in: ['contract_open', 'contract_signed', 'liquidity_processing', 'liquidity_completed'] },
  }])
  can('view-all-filters', 'Contract')
  // xem báo cáo đội của tôi (chỉ đội nghiệp vụ)
  if (userData.department?.code?.toLowerCase()?.startsWith('d')
    && userData.role !== 'admin') {
    uCan.push(['read', 'ReportsMyTeam'])
    uCan.push(['read', 'OverviewMyTeam'])
    uCant.push(['view-all-filters', 'Contract']) // chỉ đội nghiệp vụ không được xem full filter
  } else {
    uCan.push(['view-all-filters', 'Contract'])
    uCan.push(['read-stars', 'TeamNote'])
    uCant.push(['read', 'ReportsMyTeam'])
    uCant.push(['read', 'OverviewMyTeam'])
  }

  if (userData.role?.toLowerCase() === 'admin' || isAdmin) {
    // ROLE === 'SUPPER ADMIN'
    uCan.push(['manage', 'all'])
    uCant.push(['approved', 'Contract'])
    uCant.push(['change-department', 'Contract', {
      status: { $nin: ['liquidity_processing', 'contract_open'] },
    }])
    uCant.push(['revoke-team', 'Contract', {
      status: { $nin: ['verify_new'] },
    }])
    uCant.push([['update-debt'], 'Contract', {
      status: { $nin: ['contract_signed', 'liquidity_processing', 'liquidity_completed'] },
    }])
    uCant.push([['download_document'], 'Contract', {
      status: { $nin: ['contract_signed', 'offer_to_sign', 'liquidity_processing', 'liquidity_completed'] },
    }])
    uCant.push(['download_request_ticket', 'Contract', {
      status: { $ne: 'entry_new' },
    }])
  }

  if (userData.role?.toLowerCase() !== 'admin') {
    // ROLE === 'normal user'
    if (!isAdmin) {
      uCant.push(['delete', 'all'])
    }

    // BY PERMISSION ROLE
    allowList.forEach(code => {
      uCan.push([code, 'system'])
      if (code === 'DASHBOARD') {
        uCan.push(['read', 'Overview'])
      }
      if (code === 'VIEW_ACTIVITY') {
        uCan.push(['read', 'Activities'])
      }
      if (code === 'VIEW_ALL_CONTRACT') {
        uCan.push(['read', 'ContractsTitle'])
        uCan.push(['read', ['all-contract', 'Contracts', 'Contract']])
      }
      if (code === 'VIEW_OWN_CONTRACT') {
        uCan.push(['read', 'ContractsTitle'])
        uCan.push(['read', ['own-contract', 'Contracts']])
        uCan.push(['read', 'Contract', {
          department_id: { $eq: userData.department_id },
        }])
        uCan.push(['read-open-contract', 'Contract', {
          status: { $in: ['verify_deny', 'contract_open'] },
        }])
      }
      if (code === 'VIEW_CONTRACT_RETURN') {
        uCan.push(['read', 'ContractsTitle'])
        uCan.push(['read', 'ContractsOpen'])
      }
      if (code === 'VIEW_REPORT') {
        uCan.push(['read', 'Reports'])
      }
      if (code === 'ADMINISTRATION') {
        uCan.push(['manage', 'Settings'])
      }
      if (code === 'DOWNLOAD_DOCUMENT') {
        uCan.push(['download_document', 'Contract', {
          status: { $in: ['contract_signed', 'offer_to_sign', 'liquidity_processing', 'liquidity_completed', 'verify_deny'] },
        }])
        uCan.push(['download_request_ticket', 'Contract', {
          status: { $eq: 'entry_new' },
        }])
      }
      if (code === 'ADD_FILE_CONTRACT') {
        uCan.push(['add-file', 'Contract'])
      }
      if (code === 'UPDATE_CONTRACT_DATE') {
        uCan.push(['update_contract_date', 'Contract'])
      }
      if (code === 'CHANGE_CREATOR') {
        uCan.push(['change-creator', 'Contract'])
      }
      if (code === 'EDIT_CONTRACT') {
        uCan.push(['edit', 'Contract', {
          status: { $in: ['entry_new', 'verify_new', 'verify_approving', 'contract_open', 'offer_to_sign', 'verify_deny', 'contract_signed', 'contract_denied', 'liquidity_processing'] },
        }])
      }
      if (code === 'UPDATE_DEBT_CONTRACT') {
        uCan.push(['update-debt', 'Contract'], {
          status: { $in: ['contract_signed', 'liquidity_processing', 'liquidity_completed'] },
        })
        uCan.push(['update-consultant-fee', 'Contract'], {
          status: { $ne: 'entry_new' },
        })
      }
      if (code === 'VIEW_DEBT_COLLECTION_PLAN') {
        uCan.push(['read', 'DebtCollection'])
      }
      if (userData.job_title_code?.toLowerCase() === 'tdpc') {
        uCan.push([['create', 'read'], 'DebtCollection'])
        uCan.push([['create', 'read', 'edit'], 'TeamPlan'])
        uCan.push(['create', 'PlanRating'])
      }

      if (code === 'VIEW_ALL_WORK_PLAN') {
        uCan.push(['read', ['PlansTitle', 'Plans']])
      }
      if (code === 'CREATE_WORK_PLAN') {
        uCan.push(['create', 'Plans'])
      }

      if (code === 'VIEW_OWN_WORK_PLAN') {
        uCan.push(['read', 'TeamPlan'])
      }

      if (code === 'DELETE_WORK_PLAN') {
        uCan.push(['delete', 'Plans'])
      }

      if (code === 'UPATE_WORK_PLAN') {
        uCan.push(['edit', 'Plans'])
      }

      if (code === 'RATING_WORK_PLAN') {
        uCan.push(['create', 'PlanRating'])
      }

      if (code === 'CREATE_UPDATE_WORK_PLAN') {
        uCan.push([['create', 'edit', 'delete'], 'NotePlan'])
      }
      if (code === 'CREATE_UPDATE_DEPARTMENT_REVIEWS') {
        uCan.push(['create', 'TeamLeaderRating'])
      }
      if (code === 'VIEW_DEBT_COLLECTION_PLAN_REPORT') {
        uCan.push(['read', 'DebtCollectionMenu'])
      }

      if (code === 'UPDATE_RESULT_DEBT_COLLECTION_PLAN') {
        // uCan.push(['read', 'DebtCollectionMenu'])
        // uCan.push(['update', 'DebtCollectionActual'])
      }
      if (code === 'CREATE_TASK_COMMENT') {
        uCan.push(['create', 'task'])
      }
      if (code === 'CREATE_NOTE_COMMENT') {
        uCan.push(['create', 'note'])
      }
      // contract status
      if (code === 'CREATE_CONTRACT') {
        uCan.push(['read', 'ContractsTitle'])
        uCan.push(['create', 'Contract'])
      }
      if (code === 'ASSIGN_CONTRACT') {
        uCan.push(['assign', 'Contract', {
          status: { $eq: 'entry_new' },
        }])
      }
      if (code === 'VERIFICATION_CONTRACT') {
        uCan.push(['verification', 'Contract', {
          status: { $in: ['verify_new', 'verify_approving'] },
          department_id: { $eq: userData.department_id },
        }])
        uCan.push(['approved', 'Contract', {
          status: { $eq: 'verify_approving' },
          department_id: { $eq: userData.department_id },
        }])
      }
      if (code === 'BROWSING_CONTRACT') {
        uCan.push(['browsing', 'Contract', {
          status: { $eq: 'offer_to_sign' },
        }])
        uCan.push(['assigned_to_team', 'Contract', {
          status: { $eq: 'contract_signed' },
          is_hand_over: { $eq: false },
        }])
      }
      if (code === 'CUSTOMER_LIQUIDITY') {
        uCan.push(['liquidating', 'Contract', {
          status: { $in: ['contract_denied', 'contract_signed', 'liquidity_processing'] },
        }])
      }
      if (code === 'UPDATE_CONTRACT_CODE') {
        uCan.push(['update', 'ContractCode', {
          status: { $in: ['contract_denied', 'contract_signed', 'liquidity_processing'] },
        }])
      }
      if (code === 'UPDATE_CONTRACT_HAS_PLAN') {
        uCan.push(['update_contract_has_plan', 'Contract'])
      }
    })
  }

  uCan.filter(a => a[0] && a[1])
    .forEach(a => can(...a))
  uCant.filter(a => a[0] && a[1])
    .forEach(a => cannot(...a))
  return new Ability(rules)
}

export default ability
