import Vue from 'vue'

// axios
import axios from 'axios'
import { $themeConfig } from '../../themeConfig'

const { host } = $themeConfig.app

const methods = ['get', 'post', 'put', 'delete']

methods.forEach(item => {
  axios[item] = (url, data) => new Promise((resolve, reject) => {
    axios[item](url, data)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
})

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: host,
  withCredentials: false,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
