export default [
  {
    path: '/contracts/list',
    name: 'contracts-list',
    component: () => import('@/views/contracts/list/ContractsList.vue'),
    meta: {
      action: 'read',
      resource: 'Contracts',
      breadcrumb: [
        {
          text: 'Hồ sơ & Hợp đồng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/open',
    name: 'contracts-open',
    component: () => import('@/views/contracts/list/ContractsOpen.vue'),
    meta: {
      action: 'read',
      resource: 'ContractsOpen',
      breadcrumb: [
        {
          text: 'Hồ sơ & Hợp đồng',
          to: '/contracts/list',
        },
        {
          text: 'Hồ sơ mở',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/create',
    name: 'contracts-create',
    component: () => import('@/views/contracts/create/ContractsCreate.vue'),
    meta: {
      action: 'create',
      resource: 'Contract',
      breadcrumb: [
        {
          text: 'Hồ sơ & Hợp đồng',
          to: '/contracts/list',
        },
        {
          text: 'Tạo hồ sơ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/detail/:id',
    name: 'contracts-detail',
    component: () => import('@/views/contracts/detail/ContractsDetail.vue'),
    meta: {
      action: 'read',
      resource: 'Contract',
      breadcrumb: [
        {
          text: 'Hồ sơ & Hợp đồng',
          to: '/contracts/list',
        },
        {
          text: 'Chi tiết hồ sơ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contracts/edit/:id',
    name: 'contracts-edit',
    component: () => import('@/views/contracts/edit/ContractsEdit.vue'),
    meta: {
      action: 'edit',
      resource: 'Contract',
      breadcrumb: [
        {
          text: 'Hồ sơ & Hợp đồng',
          to: '/contracts/list',
        },
        {
          text: 'Chỉnh sửa hồ sơ',
          active: true,
        },
      ],
    },
  },
]
