import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import { abilitiesPlugin, Can } from '@casl/vue'
import ability from '@/libs/acl/ability'
import router from './router'
import store from './store'
import App from './App.vue'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import './global/index'

Vue.use(VueViewer)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('Can', Can)

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
