export default [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/overview/ReportsOverview.vue'),
    meta: {
      action: 'read',
      resource: 'Reports',
      breadcrumb: [
        {
          text: 'Báo cáo tổng hợp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/team/:id',
    name: 'reports-team',
    component: () => import('@/views/reports/team/ReportsTeam.vue'),
    meta: {
      action: 'read',
      resource: 'Reports',
      breadcrumb: [
        {
          text: 'Báo cáo đội',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/my-team',
    name: 'reports-my-team',
    component: () => import('@/views/reports/team/ReportsTeam.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Báo cáo đội của tôi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/user/:id',
    name: 'reports-user',
    component: () => import('@/views/reports/user/ReportsUser.vue'),
    meta: {
      action: 'read',
      resource: 'Reports',
      breadcrumb: [
        {
          text: 'Báo cáo nhân viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/user-pkh/:id',
    name: 'reports-user-pkh',
    component: () => import('@/views/reports/user-pkh/ReportsUserPkh.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Báo cáo NV PKH',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/me',
    name: 'reports-me',
    component: () => import('@/views/reports/me/ReportsMe.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Báo cáo cá nhân',
          active: true,
        },
      ],
    },
  },
  {
    path: '/debt-collection',
    name: 'debt-collection',
    component: () => import('@/views/reports/debt-collection/list/DebtCollectionList.vue'),
    meta: {
      action: 'read',
      resource: 'DebtCollectionMenu',
      breadcrumb: [
        {
          text: 'Danh sách thu nợ',
          active: true,
        },
      ],
    },
  },
]
