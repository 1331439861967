import store from '@/store'

const TEXT_UNAUTHORIZED = 'Cần đăng nhập trước!'

const http = {
  getISODate(d = new Date()) {
    try {
      const r = new Date(d)
      return r.toISOString()
    } catch (e) {
      return d
    }
  },
  formatDate(date, withTime = false) {
    if (!date) return ''
    try {
      const _ = new Date(date)
      let D = _.getDate()
      D = D < 10 ? `0${D}` : D
      let M = _.getMonth() + 1
      M = M < 10 ? `0${M}` : M
      let Y = _.getFullYear()
      Y = Y < 10 ? `0${Y}` : Y
      let h = _.getHours()
      h = h < 10 ? `0${h}` : h
      let m = _.getMinutes()
      m = m < 10 ? `0${m}` : m
      return `${D}/${M}/${Y}${withTime ? ` ${h}:${m}` : ''}`
    } catch (e) {
      return date
    }
  },
  // format client contract
  fcContract(c) {
    const res = { ...c }
    Object.assign(res, {
      reply_at: this.getISODate(c.reply_at),
      original_amount: parseFloat(c.original_amount) || 0,
      interest_amount: parseFloat(c.interest_amount) || 0,
      fee_to_buy_original: parseFloat(c.fee_to_buy_original) || 0,
      fee_to_buy_interest: parseFloat(c.fee_to_buy_interest) || 0,
      fee_for_consultant: parseFloat(c.fee_for_consultant) || 0,
      fee_for_consultant_input: parseFloat(c.fee_for_consultant_input) || 0,
      fee_for_consultant_contract: parseFloat(c.fee_for_consultant_contract) || 0,
      fee_for_consultant_service: parseFloat(c.fee_for_consultant_service) || 0,
      ratio_of_pay_consultant: parseFloat(c.ratio_of_pay_consultant) || 20,
      ratio_of_refund: parseFloat(c.ratio_of_refund) || 0,
      amount_of_refund: parseFloat(c.amount_of_refund) || 0,
      amount_to_buy_interest: parseFloat(c.amount_to_buy_interest) || 0,
      amount_to_buy_original: parseFloat(c.amount_to_buy_original) || 0,
      amount_of_break_contract: parseFloat(c.amount_of_break_contract) || 0,
      fee_to_break_contract: parseFloat(c.fee_to_break_contract) || 0,
    })
    return res
  },
  api(storeDispatch, cb) {
    return storeDispatch
      .then(res => {
        const data = res.data || res
        if (cb) cb(data)
        return data
      })
      .catch(err => {
        const resp = err.response || {}
        if (resp.status === 500 && resp.statusText === 'Internal Server Error' && (resp.data?.message === 'Unauthorized' || resp.data?.message === 'verify jwt error: token is revoked')) {
          store.commit('app/onTriggerLogout')
          throw TEXT_UNAUTHORIZED
        }
        let eMsg = err?.response?.data?.message
        eMsg = eMsg || err?.response?.data?.error?.message || err
        throw eMsg
      })
  },
  buildQuery(params) {
    if (!params) {
      return ''
    }
    if (typeof params === 'string') {
      return params
    }
    if (typeof params !== 'object') {
      return ''
    }
    const arr = []
    const keys = Object.keys(params)
    for (let i = 0; i < keys.length; i += 1) {
      if (params[keys[i]] !== '' && params[keys[i]] !== undefined && params[keys[i]] !== null) arr.push(`${encodeURIComponent(keys[i])}=${params[keys[i]] === undefined ? '' : encodeURIComponent(params[keys[i]])}`)
    }
    return `?${arr.join('&')}`
  },
  formatNumber(value = '', keepFraction = 0) {
    if (!value) return '0'
    let res = value.toString()
    try {
      if (!Number.isNaN(res)) {
        let fractionNumber = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[1] || ''
        res = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[0] || ''
        res = res.replaceAll(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? '.' : ',', '')

        res = parseFloat(res) || 0

        fractionNumber = fractionNumber.substr(0, keepFraction)
        if (fractionNumber) {
          res = `${res},${fractionNumber}`
        }
        res = res.toLocaleString(this.NUMBER_LOCALE_FORMAT)
      }
    } catch (e) {
      console.log(`Exception error formatInputNumber: ${value}`)
    }
    return res || 0
  },
}

export default http
