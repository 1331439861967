import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    logoutNow: false,
    currentRouteName: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    onTriggerLogout(state) {
      state.logoutNow = true
    },
    offTriggerLogout(state) {
      state.logoutNow = false
    },
    updateCurrentRouteName(state, val) {
      state.currentRouteName = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    exportExcelReportOverview: (ctx, q) => axiosIns.get(`/api.report/sum/export${q || ''}`),
    exportExcelReportTeam: (ctx, data) => axiosIns.get(`/api.report/debt/${data.department_id}/export${data.q || ''}`),
    exportReportTeamCurrentContract: (ctx, data) => axiosIns.get(`/api.report/debt-report/${data.department_id}/export${data.q || ''}`),
  },
}
