<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { onUnmounted, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import http from '@/global/http'
import notificationStoreModule from '@core/layouts/components/app-navbar/components/notificationStoreModule'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn } from '@/auth/utils'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      inactivityTimeout: null, // Variable to store the timeout reference
      activeTime: 0,
      lastActiveTime: null,
      isTabActive: true,
      intervalId: null,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    triggerLogout() { return store.state.app.logoutNow },
  },
  watch: {
    triggerLogout(n) {
      if (!n) return
      localStorage.clear()
      store.commit('app/offTriggerLogout')
      this.$router.push('/logout')
    },
    $route: {
      immediate: true,
      handler(n) {
        const routeName = n.name
        store.commit('app/updateCurrentRouteName', routeName)
      },
    },
  },
  created() {
    http.api(store.dispatch('notification/fetchNotifications', http.buildQuery({
      page: 0,
      perpage: 1,
    }))).catch(e => {
      if (e === $themeConfig.app.textUnauthorized) {
        this.$router.push('/logout')
      }
    })
    if (isUserLoggedIn()) this.exchangeToken()
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    // eslint-disable-next-line global-require
    console.log(require('../package.json').version)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    this.intervalId = setInterval(this.resetInactivityTimer, 1000) // Call every second
  },
  beforeDestroy() {
    // Clear the timeout and remove event listeners when the component is destroyed
    clearTimeout(this.inactivityTimeout)
    clearInterval(this.intervalId)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    exchangeToken() {
      axiosIns.post('/api.auth/token/exchange')
        .then(res => {
          const jwt = res.data?.data?.jwt
          if (!jwt) return
          useJwt.setToken(jwt)
        })
        .catch(err => {
          this.$toastr(err, 'danger')
        })
    },
    resetInactivityTimer() {
      if (!this.isTabActive || !isUserLoggedIn()) return // Do nothing if the tab is not active or user is not logged in

      // Clear any existing timeout
      clearTimeout(this.inactivityTimeout)

      // Calculate the active time
      const now = Date.now()
      if (this.lastActiveTime) {
        this.activeTime += now - this.lastActiveTime
      }
      this.lastActiveTime = now

      // Check if the total active time has reached 3 minutes
      if (this.activeTime >= 180000) { // 3 minutes
        this.exchangeToken()
        this.activeTime = 0 // Reset active time after calling the API
      }

      // Set a new timeout to detect inactivity
      this.inactivityTimeout = setTimeout(() => {
        this.lastActiveTime = null
      }, 60000) // 1 minute
    },
    handleVisibilityChange() {
      this.isTabActive = !document.hidden
      if (this.isTabActive) {
        // Reset the inactivity timer when the tab becomes active
        this.resetInactivityTimer()
      } else {
        // Pause the active time calculation when the tab becomes inactive
        // eslint-disable-next-line no-lonely-if
        if (this.lastActiveTime) {
          const now = Date.now()
          this.activeTime += now - this.lastActiveTime
          this.lastActiveTime = null
        }
      }
    },
  },

  setup() {
    const NOTIFICATION_STORE_MODULE_NAME = 'notification'
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, notificationStoreModule)
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
